<template>
  <div class="container-fluid full-section">
    <div class="container">
      <div class="row">
        <div class="col-md-8 error-wrapper">
          <h4 class="error-title">
            У вас нет доступа к данному разделу или сайту.
          </h4>
          <!--          <h4>You do not have access to this page.</h4>-->
          <p class="error-text">
            Пожалуйста, авторизуйтесь под другой учетной записью.
          </p>
          <!--          <p>Please log in with another account. </p>-->
          <button @click="logout" class="logout">Выйти</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";

export default {
  name: "Forbidden",
  metaInfo() {
    return {
      title: `Доступ к данному ресурсу ограничен - ЛУКМАРКЕТ`,
    };
  },
  ...mapActions("account", ["logout"]),
};
</script>

<style scoped>
.row {
  min-height: 100vh;
  align-items: center;
}
.error-title,
.error-text {
  font: 400 1.5rem/144% Futuris;
  color: #4e4e4e;
  text-align: center;
  width: 50%;
  margin: 0 auto 0;
}

.error-wrapper {
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  gap: 1.25rem;
}

.logout {
  border-radius: 0;
  font: 600 1.5rem/144% Futuris;
  border-width: 1px;
  padding: 0.75rem;
  text-transform: uppercase;
  position: relative;
  background-color: #e2173d;
  font-size: 1rem;
  color: #fff;
  outline: none;
  cursor: pointer;
  z-index: 1;
  transition: all 0.6s linear;
  width: 50%;
  margin: 1.5rem auto 0;
}
</style>
